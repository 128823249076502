


































































































































import Vue from "vue";
import funclient from "@/plugins/funclient";

interface FormDataInterface {
  name: string,
  email: string,
  phone: string,
  payment: string,
  message?: string,
}

export default Vue.extend({
  name: "RegistrationForm",
  computed: {
    isValidForm() {
      const formData = (this as any).formData as FormDataInterface;
      return (
          formData.name.trim().length > 0 &&
          formData.email.trim().length > 0 &&
          /\S+@\S+\.\S+/.test(formData.email) &&
          formData.phone.trim().length > 0 &&
          formData.payment.trim().length > 0
      );
    },
  },
  data() {
    return {
      sent: false,
      isSending: false,
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
        payment: "",
      },
    };
  },
  methods: {
    resetFormData() {
      this.formData = {
        name: "",
        email: "",
        phone: "",
        message: "",
        payment: "",
      }
    },
    getPaymentData() {
      return {
        //Parametros compra (obligatorio)
        name: "Certificación Internacional Fitness Infantil",
        description: "Certificación Internacional Fitness Infantil Oct 01 y 02 de 2021",
        invoice: (new Date()).getMilliseconds(),
        currency: "cop",
        amount: "1400000",
        tax_base: "0",
        tax: "0",
        country: "co",
        lang: "es",
        //Onpage="false" - Standard="true"
        external: "true",
        //Atributos opcionales
        // extra1: "extra1",
        // extra2: "extra2",
        // extra3: "extra3",
        confirmation: "https://eventosfitness.com/api/confirmation",
        response: "https://eventosfitness.com/api/response",
        //Atributos cliente
        name_billing: (this as any).formData.name,
        //atributo deshabilitación metodo de pago
        methodsDisable: [],
      };
    },
    async validateAndSend() {
      try {
        this.isSending = true;
        this.$recaptcha("register");
        const res = await funclient.post("register", {}, (this as any).formData);
        if (res.data?.success) {
          this.sent = true;
          this.isSending = false;
        } else {
          alert(`Se ha producido un error: ${res.data?.message}`);
        }
        if ((this as any).formData.payment === "enlinea") {
          const handler = (window as any).ePayco.checkout.configure({
            key: "d433843e592abe575a2d0ca78d95b514",
            test: false,
          });
          const data = this.getPaymentData();
          handler.open(data);
        }
      } catch (err) {
        alert(`Se ha producido un error: ${err}`);
      }
    },
  },
});
